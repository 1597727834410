import React from "react";

const BusinessLicenseInfo: React.FC = () => {
    const licenseNumber = process.env.REACT_APP_LICENSE_NUMBER;
    return (
        <div className='bg-gray-100 p-3 rounded-lg shadow-md text-gray-700 text-center mb-4'>
            <div className="flex justify-between items-center">
                    <img
                        src="seal_of_California.svg"
                        alt="Seal of California"
                        className="inline-block w-16 h-16 "
                    />
                    <img
                        src="dca_logo_color.png"
                        alt="DCA Logo"
                        className="inline-block h-12 "
                    />
                </div>
            <p className="text-lg font-bold">License # {licenseNumber}</p>
        </div>
    );
};

export default BusinessLicenseInfo;
